import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import localStorageManager from '../plugins/localStorageManager';

function PublicRoute() {
    let isAuthenticated = localStorageManager.userAuth.getToken();

    return (
        isAuthenticated !== null ? <Navigate to='/advList' /> : <Outlet />
    );
}

export default PublicRoute;