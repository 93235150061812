import axios from 'axios';
import localStorageManager from '../../localStorageManager';

/** Post with token */
export const postToken = (url, data) => {
    const token = localStorageManager.userAuth.getToken();

    // var axios = require('axios');
    var newData = data.append === undefined ? JSON.stringify(data) : data

    var config = {
        method: 'post',
        url: url,
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer ' + token,
        },
        data: newData
    };

    return axios(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            localStorageManager.userAuth.deleteToken();
            window.location.href = '/login'
            return error;
        });
}