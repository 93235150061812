import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import localImages from '../../assets/images';
import localStorageManager from '../../plugins/localStorageManager';

function Topbar(props) {
    const navigate = useNavigate();

    /** Function used to open and close the phone-sized menu */
    const initLeftMenuCollapse = () => {
        document.querySelector('body').classList.toggle('sidebar-enable');
        if (window.innerWidth >= 992) {
            document.querySelector('body').classList.toggle('vertical-collpsed');
        } else {
            document.querySelector('body').classList.remove('vertical-collpsed');
        }
    }

    const logout = () => {
        localStorageManager.userAuth.deleteToken();
        navigate('/login');
    }

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <Link to='/advList' className="logo logo-dark">
                            <span className="logo-sm">
                                <img src={localImages.logo} alt="logo-sm-dark" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src={localImages.logo} alt="logo-dark" height="20" />
                            </span>
                        </Link>
                        {/* <Link to='/dashboard' className="logo logo-light">
                            <span className="logo-sm">
                                <img src="assets/images/logo-sm-light.png" alt="logo-sm-light" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/logo-light.png" alt="logo-light" height="20" />
                            </span>
                        </Link> */}
                    </div>
                    <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn" onClick={initLeftMenuCollapse}>
                        <FontAwesomeIcon className='fa-thin align-middle icon-color' icon={faBars} />
                    </button>
                </div>
                <div className="d-flex align-items-center">
                    <NavDropdown title={<div>
                        {/* <span className="d-none d-xl-inline-block icon-color">Admin</span> */}
                        <FontAwesomeIcon className='rounded-circle icon-color' icon={faUser} />
                    </div>} id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={logout}>
                            Çıkış Yap
                        </NavDropdown.Item>
                    </NavDropdown>
                    {/* <NavDropdown title={<div>
                        <img className='header-language' src={localImages[props.language]}
                            alt="Header Avatar" />
                    </div>} id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => setChangeLanguage('tr')}>
                            <img className='header-language' src={localImages.tr}
                                alt="language" />
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setChangeLanguage('de')}>
                            <img className='header-language' src={localImages.de}
                                alt="language" />
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setChangeLanguage('us')}>
                            <img className='header-language' src={localImages.us}
                                alt="language" />
                        </NavDropdown.Item>
                    </NavDropdown> */}
                </div>
            </div>
        </header>
    )
}

export default Topbar;
