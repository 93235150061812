import '../../assets/scss/navbar/Navbar.scss';
import React from 'react';
import Leftbar from './Leftbar';
import Topbar from './Topbar';

function HeaderMenu() {
    return (
        <>
            <Topbar />
            <Leftbar />
        </>
    )
}

export default HeaderMenu;
