import { baseUrl } from "../Api"
import { postToken } from "../config/postRequest"

export const adminLogin = (email, password) => {
    var data = {
        'email': email,
        'password': password
    }

    return postToken(`${baseUrl}/Users/AdminLogin`, data)
}