import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Alert } from 'react-bootstrap';

const AlertMessage = forwardRef((props, ref) => {

    const [alertInfo, setAlertInfo] = useState({
        show: false,
        variant: '',
        content: ''
    })

    useImperativeHandle(ref, () => ({
        /** Successful message alert */
        successMessage(message) {
            window.scroll(0, 0);
            setAlertInfo({ show: true, variant: 'success', content: message });

            setTimeout(() => {
                setAlertInfo({ show: false, variant: '', content: '' })
            }, 2000);
        },
        /** Failed message alert */
        errorMessage(message) {
            window.scroll(0, 0);
            setAlertInfo({ show: true, variant: 'danger', content: message });

            setTimeout(() => {
                setAlertInfo({ show: false, variant: '', content: '' })
            }, 2000);
        },
        infoMessage(message) {
            window.scroll(0, 0);
            setAlertInfo({ show: true, variant: 'warning', content: message });

            setTimeout(() => {
                setAlertInfo({ show: false, variant: '', content: '' })
            }, 2000);
        }
    }))

    return (
        alertInfo.show ?
            <Alert key={alertInfo.variant} variant={alertInfo.variant} style={{ margin: '20px' }}>
                {alertInfo.content}
            </Alert>
            : ''
    );
});

export default AlertMessage;
