import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import localImages from '../../assets/images'
import AlertMessage from '../../helpers/utils/AlertMessage';
import { adminLogin } from '../../plugins/Api/module/Login'
import localStorageManager from '../../plugins/localStorageManager';
import Loading from '../shared/loading/Loading';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const childRef = useRef();
    const navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (email.length !== 0 || password.length !== 0) {
            const response = await adminLogin(email, password);

            if (response.message === 'OK') {
                setLoading(false);
                childRef.current.successMessage('Giriş yapıldı.');
                localStorageManager.userAuth.setToken(response.data.token);
                navigate('/advList');
            } else {
                childRef.current.errorMessage('Giriş Yapılamadı')
                setLoading(false);
            }
        } else {
            childRef.current.infoMessage('Email ya da şifre boş bırakılamaz.')
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-lg-4">
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9">
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <img src={localImages.logo} alt="" height="26" className="auth-logo logo-dark mx-auto" />
                                                    <img src={localImages.logo} alt="" height="26" className="auth-logo logo-light mx-auto" />
                                                </div>
                                                <h4 className="font-size-18 mt-4">Yatbu Admin Paneline Hoşgeldiniz!</h4>
                                                <p className="text-muted">Admin paneline giriş yapınız.</p>
                                            </div>
                                            <div className="p-2 mt-5">
                                                <AlertMessage ref={childRef} />
                                                <form className="" onSubmit={login}>
                                                    <div className="mb-3 auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                        <label htmlFor="username">Email</label>
                                                        <input type="text" className="form-control" id="email" onChange={(e) => setEmail(e.currentTarget.value)} value={email} placeholder="yatbu@yatbu.com" />
                                                    </div>
                                                    <div className="mb-3 auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label htmlFor="userpassword">Şifre</label>
                                                        <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.currentTarget.value)} value={password} placeholder="Şifre" />
                                                    </div>
                                                    <div className="mt-4 text-center">
                                                        <button className="btn btn-primary w-md waves-effect waves-light" style={{ boxShadow: 'none' }} type="submit">{loading ? <Loading /> : 'Giriş Yap'}</button>
                                                    </div>
                                                    {/* 
                                                    <div className="mt-4 text-center">
                                                        <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                                    </div> */}
                                                </form>
                                            </div>
                                            {/* <div className="mt-5 text-center">
                                                <p>Don't have an account ? <a href="auth-register.html" className="fw-medium text-primary"> Register </a> </p>
                                                <p>© <script>document.write(new Date().getFullYear())</script> Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
