import './assets/css/app.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icons.min.css';
import './App.scss';
import LoginPage from './pages/LoginPage';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import routes from './routes/Routes';
import PageLoading from './components/shared/loading/PageLoading';

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path='/login' element={<LoginPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          {
            routes.map(({ path, exact, component: Component }, index) => (
              <Route key={index} path={`/${path}`} exact={exact} element={<Component />} />
            ))
          }
        </Route>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
