import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal, faBusinessTime, faBook, faUserTie, faBuilding, faBell } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { menuItem } from './Menu';
import { library } from '@fortawesome/fontawesome-svg-core';

function Leftbar() {
    library.add(faGripHorizontal, faBusinessTime, faBook, faUserTie, faBuilding, faBell);

    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Menü</li>
                        {
                            menuItem.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.link} className="waves-effect">
                                        <FontAwesomeIcon icon={item.icon} className="me-2 icon-color" />
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Leftbar;
