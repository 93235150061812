import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderMenu from '../components/navbar';
import localStorageManager from '../plugins/localStorageManager';

const PrivateRoute = (props) => {
    const data = localStorageManager.userAuth.getToken();
    if (data === null) window.location.href = '/login';

    return (
        data !== null ? <><HeaderMenu /> <Outlet /></> : ''
    );
}

export default PrivateRoute;
