const storageKey = 'user';

const setToken = (value) => {
    localStorage.setItem(storageKey, value);
}

const getToken = () => {
    return localStorage.getItem(storageKey);
}

const deleteToken = () => {
    localStorage.removeItem(storageKey);
}

export const userAuth = {
    setToken,
    getToken,
    deleteToken
}