import React from 'react';
import { Spinner } from 'react-bootstrap';
import '../../../assets/scss/loading/Loading.scss';

function Loading(props) {
    return (
        <Spinner animation="border" role="status" className='loading' variant={props.variant}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    )
}

export default Loading;
