import React from 'react';
import '../../../assets/scss/loading/Loading.scss';

function PageLoading() {
    return (
        <div className='page-loader'>
            <div className='loader'>
                <span></span>
            </div>
        </div>
    )
}

export default PageLoading;
