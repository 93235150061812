import { lazy } from "react";

const routes = [
    {
        path: 'advList',
        component: lazy(() => import('../pages/AdvListPage')),
        exact: true
    },
]

export default routes;